import React from 'react';
import cls from 'classnames';
import { useEnvironment } from '@wix/yoshi-flow-editor';

import { useSettings, useStyles } from '@wix/tpa-settings/react';
import settingsParams from 'Groups/settingsParams';
import stylesParams from 'Groups/stylesParams';

import { useDidUpdate } from 'common/hooks';
import { useController } from 'common/context/controller';

import { Box } from 'wui/Box';
import { Alignment } from 'wui/Box/types';

import { Show } from 'wui/Show';
import { Typography } from 'wui/Typography';
import { Wire } from 'wui/Wire';

import { CentralFeed } from '../../CentralFeed/loadable';

import classes from './FeedWithTitle.scss';

export function FeedWithTitle() {
  const styles = useStyles();
  const settings = useSettings();
  const { isMobile } = useEnvironment();
  const { centralFeed$ } = useController();

  const showFeed = settings.get(settingsParams.showFeed);
  const { htmlTag } = styles.get(stylesParams.feedTitleFont);

  useDidUpdate(() => {
    if (showFeed) {
      centralFeed$.fetch();
    }
  }, [showFeed]);

  if (isMobile) {
    return (
      <Show if={showFeed}>
        <Box className={cls(classes.root, classes.mobile)}>
          <Wire
            cssVarName="showFeedTitle--flex"
            legacyFallback={
              settings.get(settingsParams.showFeedTitle) || isMobile
            }
          >
            <Typography
              as={htmlTag as React.ElementType}
              variant="h2-20"
              id="feed-title"
              className={classes.title}
              align={
                settings.get(
                  settingsParams.headerAlignment,
                ) as React.CSSProperties['textAlign']
              }
            >
              {settings.get(settingsParams.feedTitle)}
            </Typography>
          </Wire>
        </Box>
        <CentralFeed className={classes.feed} />
      </Show>
    );
  }

  return (
    <Show if={showFeed}>
      <Box align={settings.get(settingsParams.feedAlignment) as Alignment}>
        <Box direction="vertical" className={classes.root}>
          <Wire
            cssVarName="showFeedTitle--flex"
            legacyFallback={settings.get(settingsParams.showFeedTitle)}
          >
            <Typography
              as={htmlTag as React.ElementType}
              variant="h2-20"
              id="feed-title"
              className={classes.title}
              align={
                settings.get(
                  settingsParams.headerAlignment,
                ) as React.CSSProperties['textAlign']
              }
            >
              {settings.get(settingsParams.feedTitle)}
            </Typography>
          </Wire>
          <CentralFeed className={classes.feed} />
        </Box>
      </Box>
    </Show>
  );
}

FeedWithTitle.displayName = 'FeedWithTitle';
