import React from 'react';
import cls from 'classnames';

import { useEnvironment } from '@wix/yoshi-flow-editor';
import { useSettings } from '@wix/tpa-settings/react';
import { UISref } from '@wix/tpa-router/react';
import { groupsSelectGroupFromList } from '@wix/bi-logger-groups/v2';

import settingsParams from 'Groups/settingsParams';

import type { IGroup } from 'store/groups';

import { Box } from 'wui/Box';
import { ListItem } from 'wui/ListItem';
import { ListItemText } from 'wui/ListItemText';
import { Typography } from 'wui/Typography';
import { Wire } from 'wui/Wire';
import { TextButton } from 'wui/TextButton';
import { ListItemIcon } from 'wui/ListItemIcon';
import type { TPAComponentProps } from 'wui/types';

import { GroupInfo } from 'common/components/GroupInfo';
import { RoleIcon } from 'common/components/RoleIcon';
import { GroupImage } from 'common/components/GroupImage';

import { ImageRatio } from 'settings/consts';

import classes from './GroupListItem.scss';

interface IGroupListItemProps extends TPAComponentProps {
  wired?: boolean;
  group: IGroup;
}

export function JoinedGroupListItem(props: IGroupListItemProps) {
  const { group, wired, className, ...rest } = props;

  const settings = useSettings();
  const { isMobile } = useEnvironment();

  return (
    <ListItem
      {...rest}
      disablePadding
      className={cls(className, { [classes.wired]: wired })}
    >
      <Wire
        cssVarName="showImage--flex"
        legacyFallback={settings.get(settingsParams.showImage)}
      >
        <ListItemIcon>
          <Box width={40} height={40}>
            <GroupImage
              width={40}
              height={40}
              resize="cover"
              className={classes.image}
              image={group.coverImage?.image}
              aspectRatio={ImageRatio.square}
            />
          </Box>
        </ListItemIcon>
      </Wire>
      <ListItemText
        title={
          <Box verticalAlign="middle">
            <Typography
              noWrap
              variant="p2-16"
              className={cls(classes.title, { [classes.mobile]: isMobile })}
            >
              <UISref
                state="group"
                params={{ slug: group.slug }}
                bi={groupsSelectGroupFromList({
                  groupId: group.id as string,
                  origin: 'new_layout_groups_sidebar_my_groups',
                })}
              >
                <TextButton data-hook="group-name" as="a" variant="secondary">
                  {group.name}
                </TextButton>
              </UISref>
            </Typography>
            <Wire
              cssVarName="showAdminBadge--inline"
              legacyFallback={settings.get(settingsParams.showAdminBadge)}
            >
              <RoleIcon role={group.role} />
            </Wire>
          </Box>
        }
        subtitle={<GroupInfo misc wired={wired} groupId={group.id as string} />}
      />
    </ListItem>
  );
}

JoinedGroupListItem.displayName = 'JoinedGroupListItem';
