import React from 'react';
import cls from 'classnames';
import { useEnvironment, useTranslation } from '@wix/yoshi-flow-editor';
import { useSettings, useStyles } from '@wix/tpa-settings/react';

import { Alignment, LayoutType } from 'settings/consts';

import stylesParams from 'Groups/stylesParams';
import settingsParams from 'Groups/settingsParams';

import { TextButton } from 'wui/TextButton';
import { Box } from 'wui/Box';
import { Show } from 'wui/Show';
import { Wire } from 'wui/Wire';
import { Typography } from 'wui/Typography';

import { CentralFeed } from '../../CentralFeed/loadable';
import { PendingGroups } from '../../PendingGroups';

import { Sidebar } from './Sidebar';
import { GroupsDialog } from './GroupsDialog';

import { GROUP_LIST_LINK_DATA_HOOK } from './dataHooks';

import classes from './SidebarLayout.scss';

export function SidebarLayout() {
  const { t } = useTranslation();
  const { isMobile } = useEnvironment();
  const settings = useSettings();
  const styles = useStyles();

  const { htmlTag } = styles.get(stylesParams.feedTitleFont);

  const [isOpen, setIsOpen] = React.useState(false);

  return (
    <div
      data-hook="layout"
      data-type={LayoutType.sideBar}
      className={cls(classes.root, {
        [classes.flip]:
          settings.get(settingsParams.sideBarAlignment) === Alignment.left,
        [classes.mobile]: isMobile,
      })}
    >
      <Show if={isMobile}>
        <Box
          gap="SP2"
          align="space-between"
          className={classes.header}
          padding={isMobile ? 'SP0 SP5' : 'SP0'}
        >
          <Wire
            cssVarName="showFeedTitle--flex"
            legacyFallback={settings.get(settingsParams.showFeedTitle)}
          >
            <Typography
              as={htmlTag as React.ElementType}
              variant="h2-20"
              id="feed-title"
              className={classes.title}
              align={
                settings.get(
                  settingsParams.headerAlignment,
                ) as React.CSSProperties['textAlign']
              }
            >
              {settings.get(settingsParams.feedTitle)}
            </Typography>
          </Wire>
          <div></div>
          <TextButton
            data-hook={GROUP_LIST_LINK_DATA_HOOK}
            onClick={handleClick}
          >
            {t('groups-web.group-list.label')}
          </TextButton>
          <GroupsDialog isOpen={isOpen} onClose={() => setIsOpen(false)} />
        </Box>
      </Show>

      <Show if={!isMobile}>
        <Wire
          cssVarName="showFeedTitle--flex"
          legacyFallback={settings.get(settingsParams.showFeedTitle)}
        >
          <Box
            gap="SP2"
            align="space-between"
            className={classes.header}
            padding={isMobile ? 'SP0 SP5' : 'SP0'}
          >
            <Typography
              as={htmlTag as React.ElementType}
              variant="h2-20"
              id="feed-title"
              className={classes.title}
              align={
                settings.get(
                  settingsParams.headerAlignment,
                ) as React.CSSProperties['textAlign']
              }
            >
              {settings.get(settingsParams.feedTitle)}
            </Typography>
          </Box>
        </Wire>
        <div className={classes.sidebar}>
          <Sidebar />
        </div>
      </Show>

      <Box
        direction="vertical"
        className={classes.main}
        gap={styles.get(stylesParams.sideBarVerticalSpacing)}
      >
        <PendingGroups />
        <CentralFeed />
      </Box>
    </div>
  );

  function handleClick() {
    setIsOpen(true);
  }
}

SidebarLayout.displayName = 'SidebarLayout';
